import React, { useState, useCallback, useMemo, useRef } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import "./WebSocketDemo.css";

const WebSocketDemo: React.FC = () => {
  const [chatInput, setChatInput] = useState("");
  const [chatLog, setChatLog] = useState("");
  const [newSocket, setNewSocket] = useState("");
  const [socketUrl, setSocketUrl] = useState("wss://truco.lesmenes.com/thegame/");
  const al: Array<MessageEvent> = [];
  const messageHistory = useRef(al);

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(socketUrl);
  messageHistory.current = useMemo((): MessageEvent<any>[] => {
    if (lastJsonMessage != null) {
      if (lastJsonMessage.type == "chat_message") {
        setChatLog(chatLog + lastJsonMessage.message + "\n");
      } else {
        console.log(lastJsonMessage);
      }
      return messageHistory.current.concat(lastJsonMessage);
    }
    return messageHistory.current;
  }, [lastJsonMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const handleInputChange = (e: any) => {
    setChatInput(e.target.value);
  };

  const handleOnKeyUp = (e: any) => {
    if (e.keyCode == 13) {
      sendJsonMessage({ type: "chat_message", message: chatInput });
      setChatInput("");
    }
  };
  const handleOnSocketKeyUp = (e: any) => {
    if (e.keyCode == 13) {
      setSocketUrl(newSocket)
    }
  };
  const handleCantarTruco = () => {
    sendJsonMessage({ type: "truco" });
  };
  const handleCantarEnvido = () => {
    sendJsonMessage({ type: "envido", puntos: 28 });
  };

  const handleSocketChange = () => {
    setNewSocket(newSocket);
  }

  return (
    <div className="container">
      <span>The WebSocket is currently {connectionStatus}</span>
      <input type="text" onChange={handleSocketChange} onKeyUp={handleOnSocketKeyUp} />
      <hr />
      <div className="chat">
        <textarea name="log" id="log" value={chatLog} readOnly />
        <input type="text" onChange={handleInputChange} onKeyUp={handleOnKeyUp} value={chatInput} />
        <button onClick={handleCantarTruco}>Truco</button>
        <button onClick={handleCantarEnvido}>Envido</button>
      </div>
    </div>
  );
};

export default WebSocketDemo;
